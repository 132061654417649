import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { RiAttachment2, RiVidiconLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import bg from "../../../assets/course.webp";
import ChapterCard from "../../../components/student/Cards/chapterCard";
import Layout from "../../../components/student/Layout/layout";
import { getSectionRequest } from "../../../modules/students-modules/sections/Actions";

const VideosChaptersList = () => {
  const [getSearchParams] = useSearchParams();
  const section_name = getSearchParams.get("name");

  const { id } = useParams();
  const section = useSelector((state) => state.sections.section);
  const isLoading = useSelector((state) => state.sections.isLoading);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) dispatch(getSectionRequest(id));
  }, [id]);
  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start">
          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            {section_name}
          </Heading>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={6}
            w="100%"
          >
            {section?.map((chapter, index) => {
              return (
                <Accordion
                  defaultIndex={[0]}
                  allowMultiple
                  mt="5px"
                  key={index}
                  w="100%"
                >
                  <AccordionItem border="0">
                    <h2>
                      <AccordionButton
                        bg="main_1"
                        color="white"
                        borderRadius="8px"
                        _focus={{ outline: "none" }}
                        _hover={{ bg: "main_1" }}
                      >
                        <Flex
                          gap={2}
                          alignItems="center"
                          flex="1"
                          textAlign="left"
                        >
                          <Avatar
                            name={chapter?.name}
                            src={bg}
                            borderRadius="8px"
                            size="md"
                            background="transparent"
                          />
                          <Text fontWeight="700" fontSize="20px">
                            {chapter?.name}
                          </Text>
                        </Flex>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4} border="0" w="100%">
                      <VStack spacing={4} w="100%">
                        {chapter?.videos?.length > 0 && (
                          <Link
                            to={`/video/${chapter?.id}?name=${chapter?.name}`}
                            style={{ width: "100%" }}
                          >
                            <ChapterCard
                              list={chapter?.videos}
                              chapter={chapter}
                              onClick={(id, url) => {
                                window.location.href = `/video/${chapter?.id}?name=${chapter?.name}&video=${url}`;
                              }}
                              content={chapter?.videos_count}
                              icon={RiVidiconLine}
                              type={"videos"}
                            />
                          </Link>
                        )}
                        {chapter?.lessons?.length > 0 && (
                          <Link
                            to={`/model-answer/${chapter?.id}?name=${chapter?.name}`}
                            style={{ width: "100%" }}
                          >
                            <ChapterCard
                              chapter={chapter}
                              onClick={() => {}}
                              list={chapter?.lessons}
                              content={chapter?.lessons_count}
                              icon={RiAttachment2}
                              type={"Model answer"}
                            />
                          </Link>
                        )}
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              );
            })}
          </Grid>
        </VStack>
      </Container>
    </Layout>
  );
};

export default VideosChaptersList;
