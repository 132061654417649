import {
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  SkeletonText,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import { RiCalendarTodoFill, RiTimerLine, RiTvLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { moreVideoViewsRequest } from "../../../modules/students-modules/videos/Actions";
import bg from "../../../assets/icons/videos.svg";
import { openAddVoucherModel } from "../../../modules/admin-modules/models/Actions";
import { countSeens } from "./func";
import AddVoucher from "../models/add-voucher";

const VideoCard = ({ data, index, selectedindex }) => {
  const date1 = dayjs(data?.expiry_date);
  const diff = date1.diff(new Date(), "day");
  const seenLoading = useSelector((state) => state.chapterVideos.seenLoading);
  const voucherLoading = useSelector(
    (state) => state.chapterVideos.voucherLoading
  );
  const dispatch = useDispatch();
  return (
    <HStack
      bg="main_1_light"
      borderRadius="8px"
      p="20px"
      pl="8px"
      color="main_1"
      w="100%"
      alignItems="flex-start"
      spacing="15px"
      onClick={() => {
        if (data?.suspended != 1) {
          countSeens(dispatch, data);
        }
      }}
    >
      {/* video */}
      <Flex
        w="70px"
        h="70px"
        borderRadius="8px"
        alignItems={"center"}
        justifyContent="center"
        display={
          data?.views_count?.views_count < data?.max_allowed_views
            ? "flex"
            : "none"
        }
      >
        <Image
          src={bg}
          borderRadius="8px"
          width="100%"
          background="transparent"
        />
      </Flex>

      {/* options */}
      {seenLoading && index == selectedindex ? (
        <HStack w="100%" bg="transparent" h="100px">
          <SkeletonText
            startColor="gray.100"
            endColor="gray.600"
            w="80%"
            mt="4"
            noOfLines={4}
            spacing="6"
          />
        </HStack>
      ) : (
        <VStack w="100%" fontSize="14px" alignItems="flex-start">
          {/* title */}
          <Text fontSize="18px" fontWeight="500">
            {data?.title}
          </Text>
          <HStack spacing="8px">
            <Icon as={RiCalendarTodoFill} />
            <Text>{dayjs(data?.created_at).format("D , MMM ,YYYY")}</Text>
          </HStack>
          {console.log(diff)}
          {diff && (
            <HStack>
              <Icon as={RiTimerLine} />
              <Text>{diff > 0 ? diff : 0} Days remaining</Text>
            </HStack>
          )}
          {data?.max_allowed_views && (
            <HStack>
              <Icon as={RiTvLine} />
              <Text w="fit-content">{`Watch ${
                data?.max_allowed_views -
                  (data?.views_count?.views_count > data?.max_allowed_views
                    ? data?.max_allowed_views
                    : data?.views_count?.views_count) || 0
              } more times (${
                data?.views_count?.views_count
                  ? data?.views_count?.views_count > data?.max_allowed_views
                    ? data?.max_allowed_views
                    : data?.views_count?.views_count
                  : 0
              }/${
                data?.max_allowed_views ? data?.max_allowed_views : 0
              })`}</Text>
            </HStack>
          )}

          {(data?.max_allowed_views <= data?.views_count?.views_count ||
            (data?.suspended == 1 && data?.user_video_suspended != 0)) && (
            <Stack
              w="100%"
              flexDirection={{ base: "column", lg: "row" }}
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Button
                bg="main_1"
                color="white"
                onClick={(e) => {
                  e.stopPropagation();
                  let formData = new FormData();
                  formData.append("video_id", data?.id);
                  dispatch(moreVideoViewsRequest(formData));
                }}
              >
                Buy with voucher 75 L.E{" "}
              </Button>
              <Button
                mt="0 !important"
                border="1px solid"
                color="main_1"
                bg="main_1_light"
                isLoading={voucherLoading}
                onClick={(e) => {
                  // e.stopPropagation();

                  dispatch(openAddVoucherModel());
                }}
              >
                Add Voucher Code
              </Button>
            </Stack>
          )}
        </VStack>
      )}
    </HStack>
  );
};

export default VideoCard;
